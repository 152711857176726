import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import theme, { GlobalStyle } from '@styles';
import Main from '@components/main';

const Layout = ({ children, header, footer }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      {header}
      <Main>{children}</Main>
      {footer}
    </>
  </ThemeProvider>
);

Layout.defaultProps = {
  header: undefined,
  footer: undefined
};

Layout.propTypes = {
  header: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node.isRequired
};

export default Layout;
